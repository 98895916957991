import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  lang: {
    locales: { es: es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary: '#0E76BB',
        secondary: '#5cb239',
        accent: '#f08406',
        error: '#b71c1c',
        black: '#222c33'
      }
    }
  }
})
